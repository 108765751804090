import React, { useEffect, useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import { Page } from "../../shared/Page/Page"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import media from "css-in-js-media"
import { Helmet } from "react-helmet"
//@ts-ignore
import ImgPat from "../../images/features/inbox/pat.jpg"
//@ts-ignore
import ImgJanet from "../../images/features/inbox/janet.jpg"

const PulseAnimation = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10rem rgba(59, 130, 246, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`

const CalloutAppear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Container = styled.div`
    margin-bottom: 100rem;

    section.hero {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 120rem 0 200rem;
        flex-direction: row-reverse;

        ${media("<tablet")} {
            padding-bottom: 80rem;
        }

        ${media(">tablet", "<largeDesktop")} {
            flex-direction: column-reverse;
            padding: 0;

            .left {
                text-align: center;
                align-items: center;
            }
        }

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 600rem;
            position: relative;
            z-index: 80;

            .pre-header {
                display: flex;
                align-items: center;
                gap: 10rem;
                font-size: 14rem;
                color: ${({ theme }) => theme.text.lighter};
                font-family: "Gilroy-Regular", sans-serif;
                margin-bottom: 10rem;
            }

            h1 {
                font-size: 55rem;
                font-family: "Gilroy-Bold", sans-serif;
                color: ${({ theme }) => theme.text.dark};
                font-weight: unset;
                margin: 0;

                span {
                    background: -webkit-radial-gradient(
                        -20% 30%,
                        #6ee7b7,
                        #6ee7b7 50%,
                        #34de99 50%,
                        #34de99 75%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            h2 {
                font-weight: normal;
                font-size: 20rem;
                color: rgb(76, 87, 115);
                line-height: 35rem;
                max-width: 490rem;
            }

            ${media("<phone")} {
                > h1:not(.desktop) {
                    font-size: 35rem;
                }

                > h2:not(.desktop) {
                    font-size: 20rem;
                }
            }
        }

        .right {
            flex: 1;
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 30rem;
            padding: 60rem;
            border-radius: 10rem;
            perspective-origin: center center;
            perspective: 2000rem;

            ${media("<tablet")} {
                display: none;
            }

            .container {
                width: 600rem;
                height: 600rem;
                position: relative;

                .back {
                    position: absolute;
                    border-radius: 20rem;
                    background: linear-gradient(135deg, #eff6ff, #ecfdf5);
                    inset: 50rem;
                    transform: translate(30rem, 30rem);
                    z-index: 10;
                }

                .head {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20rem;
                    gap: 5rem;

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .title {
                        font-family: "Gilroy-SemiBold", sans-serif;
                        color: ${({ theme }) => theme.text.dark};
                        font-size: 16rem;
                    }

                    .total {
                        font-family: "Gilroy-SemiBold", sans-serif;
                        font-size: 16rem;
                        color: #1e3a8a;
                    }

                    .time-range {
                        display: flex;
                        align-items: center;
                        gap: 5rem;
                        font-size: 12rem;
                        color: ${({ theme }) => theme.text.light};

                        svg {
                            width: 20rem;
                            height: 20rem;
                        }
                    }

                    .adjustment {
                        color: #3b82f6;
                        font-size: 12rem;
                    }
                }

                .graph {
                    position: absolute;
                    width: 400rem;
                    height: 400rem;
                    background: #fff;
                    display: flex;
                    flex-direction: column;
                    box-shadow: #e0f2fe 0 8rem 24rem;
                    z-index: 20;
                    border-radius: 15rem;
                    padding: 30rem 35rem;
                    text-rendering: optimizeLegibility;

                    &.outcomes {
                        box-shadow: #ecfdf5 0 8rem 24rem,
                            rgba(100, 100, 111, 0.2) 0rem 7rem 29rem 0;
                        bottom: 0;
                        right: 0;
                        height: 250rem;

                        .grid {
                            background-color: #ffffff;
                            opacity: 1;
                            background-size: 30px 30px;
                            background-image: repeating-linear-gradient(
                                0deg,
                                #f1f5f9,
                                #f1f5f9 1.5px,
                                #ffffff 1.5px,
                                #ffffff
                            );
                        }

                        .total {
                            color: #064e3b;
                        }

                        .adjustment {
                            color: #10b981;
                        }
                    }

                    .grid {
                        flex: 1;
                        background-color: #ffffff;
                        opacity: 1;
                        background-image: radial-gradient(
                            #cbd5e1 0.75px,
                            #ffffff 0.75px
                        );
                        background-size: 15px 15px;
                        position: relative;

                        svg {
                            position: absolute;
                            bottom: 0;
                        }

                        .callout {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            gap: 10rem;
                            top: 80rem;
                            left: 76rem;
                            z-index: 20;
                            opacity: 0;

                            .text {
                                background: #3b82f6;
                                padding: 5rem 10rem;
                                color: #fff;
                                border-radius: 15rem 5rem 5rem 15rem;
                                font-size: 12rem;
                            }

                            .point {
                                display: block;
                                width: 10rem;
                                height: 10rem;
                                border-radius: 10rem;
                                background: #3b82f6;

                                animation: ${PulseAnimation} 2s infinite;
                            }

                            animation: ${CalloutAppear} 1s 1;
                            animation-delay: 2s;
                            animation-fill-mode: forwards;
                        }
                    }

                    .columns {
                    }
                }
            }
        }
    }

    section.quick-feats {
        display: flex;
        align-items: center;
        margin: 0 -20rem;
        padding: 70rem 20rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), transparent);

        > div {
            display: flex;
            gap: 90rem;

            ${media("<desktop")} {
                > div {
                    min-width: calc(50% - 40rem);
                }

                flex-wrap: wrap;
                gap: 50rem;
            }

            ${media("<=phone")} {
                flex-direction: column;

                > div:nth-of-type(even) {
                    min-width: unset;
                    align-items: flex-end;
                    text-align: right;
                }
            }

            > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 15rem;

                svg {
                    width: 50rem;
                    height: 50rem;
                }

                .title {
                    font-size: 20rem;
                    color: ${({ theme }) => theme.text.dark};
                    font-family: "Gilroy-SemiBold", sans-serif;
                }

                .description {
                    color: ${({ theme }) => theme.text.normal};
                    font-size: 15rem;
                    line-height: 30rem;
                }
            }
        }
    }
`

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const LINE_COUNT = 100
const X_MAX = 300
const Y_MAX = 247
const FREQUENCY = 4.6
const AMPLITUDE = 20
const JITTER = 20

const FeatureInBrowser = ({ data, location }) => {
    const animationFrameRef = useRef<number>(0)
    const isMounted = useRef(true)
    const card1Ref = useRef<HTMLDivElement>(null)
    const card2Ref = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<Array<{ x: number; y: number; height: number }>>([
        { x: 5, y: 1, height: 0 },
        { x: 2, y: 15, height: -50 },
        { x: 8, y: 35, height: 200 },
    ])

    const [chartAreaD, setChartAreaD] = useState("")
    const [chartLineD, setChartLineD] = useState("")

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }

            const new1X = Math.cos((valuesRef.current[0].x += 0.009)) * 6
            const new1Y = Math.cos((valuesRef.current[0].y += 0.003)) * 8
            const new1Height =
                Math.cos((valuesRef.current[0].height += 0.007)) * 12

            const new2X =
                -Math.abs(Math.cos((valuesRef.current[1].x += 0.009)) * 2) - 5
            const new2Y = Math.cos((valuesRef.current[1].y += 0.008)) * 9
            const new2Height =
                Math.cos((valuesRef.current[1].height += 0.03)) * 5

            const new3X = Math.cos((valuesRef.current[2].x += 0.008)) * 3
            const new3Y = Math.cos((valuesRef.current[1].y += 0.008)) * 9
            const new3Height =
                Math.cos((valuesRef.current[2].height += 0.03)) * 5

            card1Ref.current.style.transform = `rotateX(${new1X}deg) rotateY(${new1Y}deg) translateY(${new1Height}rem)`
            // card2Ref.current.style.transform = `rotateX(${new2X}deg) rotateY(${new2Y}deg) translateY(${new2Height}rem)`
            card2Ref.current.style.transform = `rotateX(${new3X}deg) rotateY(${new3Y}deg) translateY(${new3Height}rem)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        // Step sizes
        const xStepSize = X_MAX / LINE_COUNT
        const yStepSize = Y_MAX / LINE_COUNT

        // Our return path
        let newD = ``

        // Populate
        for (let i = 0; i < LINE_COUNT; i++) {
            const yPoint = Math.min(
                Y_MAX,
                Math.cos(i / FREQUENCY) * AMPLITUDE +
                    i * yStepSize +
                    Math.random() * JITTER
            )

            if (i === 0) {
                newD += `M ${xStepSize * i},${Y_MAX - yPoint}`
            }

            newD += ` L ${xStepSize * i},${Y_MAX - yPoint}`
        }

        setChartLineD(newD)

        newD += `L${X_MAX},${Y_MAX} L0,${Y_MAX}`

        setChartAreaD(newD)

        if (animationFrameRef.current === 0) {
            animationFrameRef.current = requestAnimationFrame(paint)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Page
            constrainedHeader
            constrainedFooter
            constrainedCta
            location={location}
        >
            <Helmet>
                <title>It's all in your browser</title>
            </Helmet>
            <Container>
                <section className={"hero pageConstrained"}>
                    <div className={"left"}>
                        <h1>
                            Make <span>data driven</span> decisions with
                            built-in <span>analytics</span>.
                        </h1>
                        <h2>
                            Easily monitor the performance of your team and call
                            data quality.
                        </h2>
                        <div>
                            <a href={"//app.23shout.com"} tabIndex={-1}>
                                <FlatButton color={"primary"} size={"larger"}>
                                    Get started for free <Chevron />
                                </FlatButton>
                            </a>
                        </div>
                    </div>
                    <div className={"right"}>
                        <div className={"container"}>
                            <div className={"back"} />
                            <div className={"graph"} ref={card1Ref}>
                                <div className={"head"}>
                                    <div>
                                        <div className={"title"}>
                                            Average talk time
                                        </div>
                                        <div className={"total"}>
                                            16 hours today
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"time-range"}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <path
                                                    d="M0 0h24v24H0V0z"
                                                    fill="none"
                                                />
                                                <path d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z" />
                                            </svg>
                                            Today
                                        </div>
                                        <div className={"adjustment"}>
                                            + 3 hours
                                        </div>
                                    </div>
                                </div>
                                <div className={"grid"}>
                                    <div className={"callout"}>
                                        <div className={"text"}>
                                            Added Joy to the team
                                        </div>
                                        <div className={"point"} />
                                    </div>
                                    <svg
                                        viewBox={`0 0 ${X_MAX} ${Y_MAX}`}
                                        stroke={"transparent"}
                                    >
                                        <defs>
                                            <linearGradient
                                                id={
                                                    "analyticsChart1FillGradient"
                                                }
                                                x1={"50%"}
                                                y1={"0%"}
                                                x2={"50%"}
                                                y2={"100%"}
                                            >
                                                <stop
                                                    offset={"0%"}
                                                    stopColor={"#E0F2FE"}
                                                />
                                                <stop
                                                    offset={"100%"}
                                                    stopColor={"#FFF"}
                                                />
                                            </linearGradient>
                                        </defs>
                                        <path
                                            d={chartAreaD}
                                            fill={
                                                "url(#analyticsChart1FillGradient)"
                                            }
                                        />
                                        <path
                                            d={chartLineD}
                                            stroke={"#7DD3FC"}
                                            fill={"transparent"}
                                        />
                                    </svg>
                                </div>
                            </div>

                            <div className={"graph outcomes"} ref={card2Ref}>
                                <div className={"head"}>
                                    <div>
                                        <div className={"title"}>
                                            Lead count
                                        </div>
                                        <div className={"total"}>
                                            38 this week
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"time-range"}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <path
                                                    d="M0 0h24v24H0V0z"
                                                    fill="none"
                                                />
                                                <path d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z" />
                                            </svg>
                                            This week
                                        </div>
                                        <div className={"adjustment"}>
                                            + 4 leads
                                        </div>
                                    </div>
                                </div>
                                <div className={"grid"}>
                                    <svg
                                        viewBox={`0 0 ${X_MAX} 100`}
                                        stroke={"transparent"}
                                    >
                                        <defs>
                                            <linearGradient
                                                id={
                                                    "analyticsChart1FillGradient"
                                                }
                                                x1={"50%"}
                                                y1={"0%"}
                                                x2={"50%"}
                                                y2={"100%"}
                                            >
                                                <stop
                                                    offset={"0%"}
                                                    stopColor={"#E0F2FE"}
                                                />
                                                <stop
                                                    offset={"100%"}
                                                    stopColor={"#FFF"}
                                                />
                                            </linearGradient>
                                        </defs>
                                        {Array(7)
                                            .fill(null)
                                            .map((v, i) => {
                                                const height = Math.max(
                                                    20,
                                                    Math.random() * 80
                                                )
                                                return (
                                                    <rect
                                                        fill={"#A7F3D0"}
                                                        x={i * 45}
                                                        y={95 - height}
                                                        width={20}
                                                        height={height}
                                                        radius={10}
                                                    />
                                                )
                                            })}
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"quick-feats"}>
                    <div className={"pageConstrained"}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                    <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                    <g>
                                        <path d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z" />
                                        <polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93" />
                                        <polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9" />
                                    </g>
                                </g>
                            </svg>
                            <div className={"title"}>Gain useful insights</div>
                            <div className={"description"}>
                                Easily see if certain data leads to lower
                                conversions, or which agents are completing
                                calls at different rates.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M21.36 6.28l-.06-.06c-.39-.39-1.03-.37-1.39.04l-2.18 2.45C15.68 6.4 12.83 5 9.61 5c-2.5 0-4.83.87-6.75 2.3-.47.35-.52 1.04-.11 1.45l.06.06c.33.33.86.39 1.23.11C5.63 7.72 7.54 7 9.61 7c2.74 0 5.09 1.26 6.77 3.24l-2.88 3.24-3.29-3.29c-.39-.39-1.02-.39-1.41 0l-6.12 6.13c-.37.37-.37.98 0 1.35l.15.15c.37.37.98.37 1.35 0l5.32-5.33 3.25 3.25c.41.41 1.07.39 1.45-.04l3.35-3.76c.62 1.12 1.08 2.39 1.32 3.73.08.47.47.82.95.82h.09c.6 0 1.05-.55.94-1.14-.32-1.85-.98-3.54-1.89-5L21.4 7.6c.34-.38.32-.96-.04-1.32z" />
                            </svg>
                            <div className={"title"}>Compare agents</div>
                            <div className={"description"}>
                                One click access to agent comparisons show
                                differences in call rates, talk time, and
                                completions.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z" />
                            </svg>
                            <div className={"title"}>
                                Historical time ranges
                            </div>
                            <div className={"description"}>
                                Your analytics data doesn't expire, select and
                                view historical figures for actioning and
                                analysis.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                    <g>
                                        <circle cx="20" cy="12" r="2" />
                                        <circle cx="4" cy="12" r="2" />
                                        <circle cx="12" cy="20" r="2" />
                                        <path d="M7.89,14.65l-2.94,2.93c-0.39,0.39-0.39,1.02,0,1.41s1.02,0.39,1.41,0l2.94-2.93c0.39-0.38,0.39-1.02,0-1.41 C8.91,14.26,8.28,14.26,7.89,14.65z" />
                                        <path d="M6.41,4.94C6.02,4.55,5.39,4.55,5,4.94C4.61,5.33,4.61,5.96,5,6.35l2.93,2.94c0.39,0.39,1.02,0.39,1.42,0 C9.73,8.9,9.73,8.27,9.34,7.88L6.41,4.94z" />
                                        <path d="M16.12,14.65c-0.39-0.39-1.02-0.39-1.42,0c-0.39,0.39-0.39,1.02,0,1.41L17.64,19c0.39,0.39,1.02,0.39,1.41,0 s0.39-1.02,0-1.41L16.12,14.65z" />
                                        <path d="M16.06,9.33l2.99-2.98c0.39-0.4,0.39-1.03,0-1.42c-0.39-0.39-1.02-0.39-1.41,0l-2.99,2.98c-0.39,0.39-0.39,1.02,0,1.42 C15.04,9.72,15.67,9.72,16.06,9.33z" />
                                        <circle cx="12" cy="4" r="2" />
                                    </g>
                                </g>
                            </svg>
                            <div className={"title"}>Realtime updates</div>
                            <div className={"description"}>
                                Zero delays in data readiness, we'll show you
                                the figures for calls that completed seconds ago
                                and update it in realtime.
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default FeatureInBrowser
